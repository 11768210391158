<template>
    <div class="content">
        <h1>
            <router-link :to="{name: 'catalogue', params: {  category_id: 'all'}}">Library</router-link> &raquo;
            <span v-if="category && category != 'all'">{{category.label}}</span>
            <span v-else>All projects</span>
        </h1>
        <ProjectsListing :items="itemsRev" :cols="4" />
    </div>
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/database';
import ProjectsListing from './ProjectsListing.vue';

export default {
    components: {
        ProjectsListing
    },
    mounted() {
        this.bindStuff()
    },
    data() {
        return {
            limit: 200,
            items: [],
            category: null
        }
    },
    watch: {
        '$route.params.category_id': function(category_id) {
            this.$unbind('items')
            if (this.category) this.$unbind('category')
            this.bindStuff()
        }
    },
    computed: {
        itemsRev: function() {
            return this.items.slice(0).reverse()
        }
    },
    methods: {
        bindStuff() {
            const db = firebase.database()

            // console.log(this.$route.params)
            const cat_id = this.$route.params.category_id
            if (cat_id && cat_id != 'all') {
                this.$bindAsArray('items', db.ref("library/projects").orderByChild('category').equalTo(cat_id).limitToLast(this.limit), null, function() {
                    // console.log('catalogue loaded')
                })
                this.$bindAsObject('category', db.ref('library/categories/' + cat_id), null, function() {
                    // console.log('category loaded')
                })
            } else {
                this.$bindAsArray('items', db.ref("library/projects").orderByKey().limitToLast(this.limit), null, function() {
                    // console.log('catalogue loaded')
                })
            }
        }
    }
}
</script>
